import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Header from './header'
import Footer from './footer'
import ContactForm from './contactform'
import "../global.css"
export default ({children, otherSlug}) => (
    <div>
      <Header otherSlug={otherSlug}/>
      {children}
      <ContactForm/>
      <Header otherSlug={otherSlug}/>
     <Footer/>
    </div>
  );
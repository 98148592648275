import React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby';
import Layout from '../components/layout'




const variea = 'data'
export default ({pageContext}) => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          en: pages (locales: en) {
            title
            slug
            content {
              html
            }
          }
          sv: pages (locales: sv) {
            title
            slug
            content {
              html
            }
          }
        }
      }
    `);
    console.log(pageContext)
    return (
      <div>
      <Layout otherSlug="/sv">
      {/* <h2>Español</h2>
      {data.gcms.es.map(page => (
        <li key={page.slug}>
          <Link to={`/es/${page.slug}/`}>{page.title}</Link>
        </li>
      ))} */}


      <h2>Deutsch</h2>
      {data.gcms.sv.map(page => (
        <li key={page.slug}>
          <Link to={`/sv/${page.slug}/`}>{page.title}</Link>
        </li>
      ))}

      <h2>English</h2>
      {data.gcms.en.map(page => (
        <li key={page.slug}>
          <Link to={`/${page.slug}/`}>{page.title}</Link>
        </li>
      ))}
      </Layout>
    </div>
    );
  };
  